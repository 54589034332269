var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = true;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// Slick slider for Timeline
$(".carouselImageMultiple").find('.carouselSlides').slick({
  slidesToShow: 1,
  centerMode: true,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 6000,
  infinite: true,
  cssEase: 'linear',
  centerPadding: '5%',

  responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '0',
      }
    }
  ]
});

// Homepage Headline Intro
// $(".homeFeaturesWrapper .homeHeadlinesIntro").insertBefore(".homeFeatures .homeFeature.homeBox4");

// Footer logos
$(".footerBox.Footer-Logos").insertAfter(".pageFooterWrapper");

$(".homeBox1, .homeBox2, .homeBox3").prependTo(".homeFeaturesWrapper").wrapAll("<div class='homeFeatures homeFeaturesTop' />");